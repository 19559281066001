import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "anxiety-and-your-teenager"
    }}>{`Anxiety and your teenager`}</h1>
    <SingleImage smallGridSize={10} gridSize={8} src="/images/m9/42-01.svg" alt="Anxiety" mdxType="SingleImage" />
    <p>
  We all feel anxious at times in our lives – it could be about a driving test, job interview, exam, sporting game or
  giving a presentation to a group of people. This anxiety is a normal part of life and it can even be helpful and keep
  us safe. Anxiety can help us avoid dangerous situations or perform at our best.
    </p>
    <p>
  However, <i>clinical anxiety</i> is more than just feeling stressed or worried. <i>Clinical anxiety</i> is when these
  anxious feelings become intense, overwhelming and long-lasting, and interfere with your teenager’s studies and other
  activities, and family and social relationships. <i>Clinical anxiety</i> can make it hard for your teenager to do
  everyday things like going to school or work. It can also affect how your teenager gets along with other people.
    </p>
    <p>
  One in six young Australians aged 15-24 years has experienced an anxiety condition in the last 12 months. This is
  equivalent to 440,000 young people today.<sup>1</sup>
    </p>
    <p>
  There are ways that parents can help their teenager manage everyday anxiety. This module will provide some tips on how
  you can help your teenager cope with and reduce their anxiety.
    </p>
    <p>
  [Note: if your teenager is currently experiencing clinical anxiety, we recommend that you help them seek help from a
  mental health professional, in addition to completing this module.]
    </p>
    <sup>
  1. See <GoTo to="/appendix" mdxType="GoTo">Appendix</GoTo> page for full reference.
    </sup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      